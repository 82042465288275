import { ReactNode, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { jaJP as gridJaJP } from "@mui/x-data-grid/locales";
import { jaJP as pickerJaJP } from "@mui/x-date-pickers";
import { jaJP as coreJaJP } from "@mui/material/locale";

import {
  HomeAppBar,
  AdminAppBar,
  ProjectAppBar,
  ExternalProjectAppBar,
} from "./AppBar";

import { HomeNavs, ProjectNavs } from "layouts/Navigation";
import { useExternalShareInfo } from "contexts/externalShareInfo";

interface LayoutProps {
  title?: string;
  children: ReactNode | ReactNode[];
}

const mdTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#104470",
        contrastText: "#fff",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1200,
        xl: 1536,
      },
    },
  },
  gridJaJP,
  pickerJaJP,
  coreJaJP
);

const boxStyle = {
  backgroundColor: (theme: any) =>
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[900],
  height: "100vh",
  overflowY: "scroll",
  width: "100%",
};

export function HomeLayout({ children }: LayoutProps) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <CssBaseline />
        <HomeAppBar open={open} toggleDrawer={toggleDrawer} />
        <HomeNavs open={open} toggleDrawer={toggleDrawer} />
        <Box component="main" sx={boxStyle}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export function AdminLayout({ title, children }: LayoutProps) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box>
        <CssBaseline />
        <AdminAppBar
          title={title as string}
          open={open}
          toggleDrawer={toggleDrawer}
        />
        <HomeNavs open={open} toggleDrawer={toggleDrawer} />
        <Box component="main" sx={boxStyle}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export function ProjectLayout({ children }: LayoutProps) {
  const [open, setOpen] = useState(false);
  const { isExternalLogin } = useExternalShareInfo();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const drawerWidth = 260;

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Box className="w-screen h-full min-h-screen flex">
        {isExternalLogin ? (
          <ExternalProjectAppBar />
        ) : (
          <>
            <ProjectAppBar open={open} toggleDrawer={toggleDrawer} />
            <ProjectNavs
              open={open}
              toggleDrawer={toggleDrawer}
              drawerWidth={drawerWidth}
            />
          </>
        )}
        <Box component="main" sx={boxStyle}>
          <div className="absolute">
            <Toolbar variant="dense" />
          </div>
          <div className="pt-10 h-full">{children}</div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
