import { ElementInput, Material } from "API";

export function getMaterial(ms: Material[], elements: any) {
  const materialElement = elements.filter((e: any) =>
    e.slug.includes("materialId")
  )[0];
  const material = ms.filter((ms) => ms.id === materialElement.value)[0];
  if (!material) return null;
  return material;
}

export function getValue(elements: any, slug: string) {
  const element = elements.filter((e: any) => e.slug.includes(slug))[0];
  if (element.type === "number" && isNaN(Number(element.value))) {
    return 0;
  }
  return element.value;
}

// 初期状態のElementを作成する
// componentの場合は
export function createEmptyElements(
  target: "component" | "decoration",
  slug: string
): ElementInput[] {
  const initialElementObj =
    target === "component"
      ? initialComponentElementObj
      : initialDecorationElementObj;
  const initialElements = initialElementObj.map((e) => ({
    name: e.name,
    type: e.type,
    slug: `${slug}.${e.slugSuffix}`,
  }));
  return initialElements;
}

// 既存のcomponent/decorationのelementに指定したものを追加する
export function addInitSubElements(
  target: "component" | "decoration",
  elementsStr: string,
  slug: string,
  addInfo: {
    targetSlugSuffix: string;
    initialValue?: any | null;
  }[]
): string {
  const targetElements = JSON.parse(elementsStr);
  const initElements = createEmptyElements(target, slug);
  for (const elem of initElements) {
    const slugSuffix = elem.slug.replace(`${slug}.`, "");
    const info = addInfo.find((i) => i.targetSlugSuffix === slugSuffix);
    if (!info) continue;
    if (!targetElements[slugSuffix]) {
      targetElements[slugSuffix] = {
        ...elem,
        value: info.initialValue,
      };
    }
  }
  return JSON.stringify(targetElements);
}

// Area以下のComponentのElementの初期値
const initialComponentElementObj: {
  name: string;
  type: string;
  slugSuffix: string;
}[] = [
  {
    name: "資材",
    type: "string",
    slugSuffix: "materialId",
  },
  {
    name: "長さ",
    type: "number",
    slugSuffix: "length",
  },
  {
    name: "巾",
    type: "number",
    slugSuffix: "width",
  },
  {
    name: "高さ",
    type: "number",
    slugSuffix: "height",
  },
  {
    name: "数量",
    type: "number",
    slugSuffix: "quantity",
  },
  {
    name: "m2",
    type: "number",
    slugSuffix: "m2",
  },
  {
    name: "m3",
    type: "number",
    slugSuffix: "m3",
  },
  {
    name: "notes",
    type: "string",
    slugSuffix: "notes",
  },
  {
    name: "呼び寸長さ",
    type: "number",
    slugSuffix: "lengthNominal",
  },
  {
    name: "呼び寸巾",
    type: "number",
    slugSuffix: "widthNominal",
  },
  {
    name: "呼び寸高さ",
    type: "number",
    slugSuffix: "heightNominal",
  },
  {
    name: "木端方向",
    type: "number",
    slugSuffix: "edge",
  },
];

// Decorations 以下のComponentのElementの初期値
const initialDecorationElementObj: {
  name: string;
  type: string;
  slugSuffix: string;
}[] = [
  {
    name: "資材",
    type: "string",
    slugSuffix: "materialId",
  },
  {
    name: "数量",
    type: "number",
    slugSuffix: "quantity",
  },
];

// その値がexprかどうかの判定
export const isExpr = (value: string | undefined): boolean => {
  // valueが数値だったら文字列に変換
  if (!isNaN(Number(value))) {
    value = String(value);
  }
  return value?.startsWith("=") ?? false;
};

// BomのElement以下に指定したslugを追加する(ない場合のみ追加)
// 初期値を外部から指定できる
export const addInitElements = (
  elementsStr: string,
  addInfo: {
    targetSlug: string;
    initialValue?: any | null;
  }[]
): string => {
  const elements = JSON.parse(elementsStr);
  for (const [
    name,
    { slug, type, initialExpr, initialValue },
  ] of Object.entries(ELEMENT_MAPPING)) {
    const info = addInfo.find((i) => i.targetSlug === slug);
    if (!info) continue;
    if (!elements[slug]) {
      elements[slug] = {
        slug,
        name,
        type,
        value: info.initialValue ?? initialValue ?? null,
        overwriteValue: null,
        expr: initialExpr,
        description: null,
        cutting: null,
      };
    }
  }
  return JSON.stringify(elements);
};

// generateInitElements はpackageのelementの初期値を生成します
export const generateInitElements = () => {
  const elements = Object.entries(ELEMENT_MAPPING).map(
    ([name, { slug, type, initialExpr }]) => ({
      name,
      slug,
      value: null,
      overwriteValue: null,
      type: type,
      cutting: null,
      description: null,
      expr: initialExpr,
    })
  );

  return elements;
};

const ELEMENT_MAPPING: {
  [key: string]: {
    slug: string;
    type: string;
    initialExpr?: string;
    initialValue?: string;
  };
} = {
  内寸長さ: { slug: "innerLength", type: "number" },
  内寸巾: { slug: "innerWidth", type: "number" },
  内寸高さ: { slug: "innerHeight", type: "number" },
  外寸長さ: { slug: "outerLength", type: "number" },
  外寸巾: { slug: "outerWidth", type: "number" },
  外寸高さ: { slug: "outerHeight", type: "number" },
  製品長さ: { slug: "productLength", type: "number" },
  製品巾: { slug: "productWidth", type: "number" },
  製品高さ: { slug: "productHeight", type: "number" },
  クリアランス長さ左: { slug: "clearanceLengthLeft", type: "number" },
  クリアランス長さ右: { slug: "clearanceLengthRight", type: "number" },
  クリアランス巾左: { slug: "clearanceWidthLeft", type: "number" },
  クリアランス巾右: { slug: "clearanceWidthRight", type: "number" },
  クリアランス無負荷材上: { slug: "clearanceHeightTop", type: "number" },
  クリアランスハリ下: { slug: "clearanceHeightTopUnderBeam", type: "number" },
  クリアランス天井下: { slug: "clearanceHeightBottom", type: "number" },
  上下クリアランス: { slug: "verticalClearance", type: "number" },
  m3: { slug: "m3", type: "number" },
  NW: { slug: "NW", type: "number" },
  風袋重量: {
    slug: "tareWeight",
    type: "number",
    initialExpr:
      "= Math.ceil( @[panelM2](panelM2) * 5.5 + @[woodM3](woodM3) * 550 )",
  },
  符号計算用外寸巾: {
    slug: "signCalcOuterWidth",
    type: "number",
    initialExpr: "= @[内寸巾](innerWidth) + 150",
  },
  Z1402用側桟長さ: {
    slug: "Z1402SideBattenLength",
    type: "number",
    initialExpr: "= @[側桟長さ](sideBattenLength)",
  },
  側支柱の中心間隔: {
    slug: "sideStrutsCenterSpace",
    type: "number",
    initialExpr: "= if( 側板巾 >= 1230 ){ 1230 } else { 側板巾 }",
  },
  Z1402組合せ符号: {
    slug: "Z1402CombinationSign",
    type: "number",
    initialExpr:
      // 引数の順番はConfluence(https://pax-cloud.atlassian.net/wiki/spaces/PAXDOC/pages/62029825/JIS)と違ってややこしいが、Z1410と合わせた形にしている
      "= Z1402calcCombinationSign( @[側支柱の中心間隔](sideStrutsCenterSpace) , @[符号計算用外寸巾](signCalcOuterWidth) , @[Z1402用側桟長さ](Z1402SideBattenLength) )",
  },
  Z1403組合せ符号: {
    slug: "Z1403CombinationSign",
    type: "number",
    initialExpr:
      "= Z1403calcCombinationSign( @[NW](NW) , @[側支柱の中心間隔](sideStrutsCenterSpace) , @[符号計算用外寸巾](signCalcOuterWidth) , @[内寸高さ](innerHeight) )",
  },
  "Z1410(レベルⅠ)組合せ符号": {
    slug: "Z1410Level1CombinationSign",
    type: "number",
    initialExpr:
      "= Z1410Level1calcCombinationSign( @[NW](NW) , @[側支柱の中心間隔](sideStrutsCenterSpace) , @[符号計算用外寸巾](signCalcOuterWidth) , @[内寸高さ](innerHeight) )",
  },
  "Z1410(レベルⅡ)組合せ符号": {
    slug: "Z1410Level2CombinationSign",
    type: "number",
    initialExpr:
      "= Z1410Level2calcCombinationSign( @[NW](NW) , @[側支柱の中心間隔](sideStrutsCenterSpace) , @[符号計算用外寸巾](signCalcOuterWidth) , @[内寸高さ](innerHeight) )",
  },
  "Z1410(レベルⅢ)組合せ符号": {
    slug: "Z1410Level3CombinationSign",
    type: "number",
    initialExpr:
      "= Z1410Level3calcCombinationSign( @[NW](NW) , @[側支柱の中心間隔](sideStrutsCenterSpace) , @[符号計算用外寸巾](signCalcOuterWidth) , @[内寸高さ](innerHeight) )",
  },
  "Z1410(レベルⅣ)組合せ符号": {
    slug: "Z1410Level4CombinationSign",
    type: "number",
    initialExpr:
      "= Z1410Level4calcCombinationSign( @[NW](NW) , @[側支柱の中心間隔](sideStrutsCenterSpace) , @[符号計算用外寸巾](signCalcOuterWidth) , @[内寸高さ](innerHeight) )",
  },
  はり受け材質: {
    slug: "beamSupportSpeciesId",
    type: "string",
  },
  はり材質: {
    slug: "beamSpeciesId",
    type: "string",
  },
  はりの中心間隔: {
    slug: "beamCenterSpace",
    type: "number",
  },
  はりの長さ: {
    slug: "beamLength",
    type: "number",
  },
  天井荷重: {
    slug: "ceilingLoad",
    type: "number",
  },
  枠組み部材選定表: {
    slug: "frameComponentSelectionTable",
    type: "string",
  },
  組立: {
    slug: "assembly",
    type: "string",
  },
  JISはり受け部材: {
    slug: "JISBeamSupportMaterial",
    type: "string",
    initialExpr:
      "= JISBeamSupportMaterial( @[天井荷重](ceilingLoad) , @[はりの長さ](beamLength) , @[側支柱の中心間隔](sideStrutsCenterSpace) , @[はり受け材質](beamSupportSpeciesId), mainSpeciesId, mainSpeciesEdgeMPa, materials, species )",
  },
  JISはり部材: {
    slug: "JISBeamMaterial",
    type: "string",
    initialExpr:
      "= JISBeamMaterial( @[天井荷重](ceilingLoad) , @[はりの長さ](beamLength) , @[はりの中心間隔](beamCenterSpace) , @[はり材質](beamSpeciesId), mainSpeciesId, mainSpeciesEdgeMPa, mainSpeciesFaceMPa, materials, species )",
  },
  はり方向: {
    slug: "JISBeamEdge",
    type: "string",
    initialExpr:
      "= JISBeamEdge( @[天井荷重](ceilingLoad) , @[はりの長さ](beamLength) , @[はりの中心間隔](beamCenterSpace) , @[はり材質](beamSpeciesId), mainSpeciesId, mainSpeciesEdgeMPa, mainSpeciesFaceMPa, materials, species )",
  },
  負荷床材許容曲げ強さ: { slug: "flooringMPa", type: "number" },
  負荷床材支点間の距離: { slug: "flooringLength", type: "number" },
  負荷床材スパン1: { slug: "flooringSpan1", type: "number" },
  負荷床材スパン2: { slug: "flooringSpan2", type: "number" },
  負荷床材巾: { slug: "flooringWidth", type: "number" },
  負荷床材高さ: { slug: "flooringHeight", type: "number" },
  負荷床材本数: { slug: "flooringNum", type: "number" },
  その他原価: {
    slug: "otherCost",
    type: "number",
  },
};
