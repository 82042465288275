import { isProductCaseKey } from "./image";

import { Project } from "API";

export function getProjectTypeName(
  type: "PACK" | "OTHER" | string | null | undefined
) {
  switch (type) {
    case "PACK":
      return "梱包";
    case "OTHER":
      return "その他";
    default:
      return "梱包";
  }
}

export enum RedirectTypes {
  schedules = "schedules",
}

/**
 * 案件の公開している画像に対象の梱包のものが含まれるか確認する
 * @param project 案件
 * @param productId 梱包ID
 * @returns 梱包の画像が含まれる場合はtrue
 */
export function isSharedProduct(project: Project, productId: string) {
  return (
    project &&
    project.externalSharedImageKeys &&
    project.externalSharedImageKeys.some(
      (key) => key && isProductCaseKey(key, productId)
    )
  );
}
