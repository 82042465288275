/* eslint-disable react-hooks/exhaustive-deps */
import useEffect from "use-deep-compare-effect";
import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect as useReactEffect,
  useRef,
} from "react";
import _ from "lodash";

import { useMaterials } from "./materials";
import { useSpecies } from "./species";
import { useAuth } from "./auth";

import type { Product } from "API";
import useDatalist from "hooks/datalist";
import { GraphQLInput, LoadOptions } from "hooks/datalist";
import { useProject } from "contexts/project";
import { useDialog } from "contexts/dialog";
import { getUpdatedCaseInputByProductNumber } from "utils/product";
import { updateArbitraryProductBomElement } from "utils/bom";
import { isSharedProduct } from "utils/project";
import { isProductCaseKey } from "utils/image";

interface ProductsContextValue {
  loading: boolean;
  creating: boolean;
  copying: boolean;
  products: Product[];
  create: (input: GraphQLInput) => Promise<void>;
  createNonPack: (input: GraphQLInput) => Promise<void>;
  copy: (input: GraphQLInput) => Promise<void>;
  update: (input: GraphQLInput) => Promise<void>;
  updateToPending: (
    productId: string,
    refetchFuncs: ((options: LoadOptions) => Promise<void>)[]
  ) => Promise<void>;
  remove: (id: string) => Promise<void>;
  link: (productId: string, quoteLineId: string) => Promise<void>;
  unlink: (productId: string, quoteLineId: string) => Promise<void>;
  refetch: (options?: LoadOptions) => Promise<void>;
}

interface ProductsContextProps {
  children: ReactNode;
  variables: { [key: string]: any };
  by?: "ProjectId" | "ScheduleId" | "PackageTypeId";
}

const ProductsContext = createContext<ProductsContextValue>({
  products: [],
  loading: false,
  creating: false,
  copying: false,
  create: () => Promise.resolve(),
  createNonPack: () => Promise.resolve(),
  copy: () => Promise.resolve(),
  update: () => Promise.resolve(),
  updateToPending: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  link: () => Promise.resolve(),
  unlink: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
});

export const ProductsProvider = ({
  variables,
  children,
  by,
}: ProductsContextProps) => {
  const {
    data,
    loading,
    creating,
    refetch,
    create,
    remove,
    update,
    appendNext,
    nextToken,
  } = useDatalist({
    query: `productsBy${by || "ProjectId"}`,
    variables,
    sort: "order",
  });

  // FIXME: 全件取得するようにしている、ページネーションを実装する必要がある
  // appendNextの実行状態を管理しないとデータが重複する
  const [fetchingNext, setFetchingNext] = useState(false);

  // nextTokenがnullになるまでappendNextを実行する
  useReactEffect(() => {
    if (nextToken && !fetchingNext) {
      // 既にフェッチ中でなければappendNextを呼び出す
      setFetchingNext(true); // フェッチ中の状態をtrueに設定
      appendNext().finally(() => setFetchingNext(false)); // フェッチが終わったらフェッチ中の状態をfalseに設定
    }
  }, [nextToken]);
  const [copying, setCopying] = useState(false);

  useEffect(() => {
    refetch(variables);
  }, [variables]);

  const {
    project,
    update: updateProject,
    updateExternalSharedImageKeys,
    isShared,
  } = useProject();
  const { allMaterials } = useMaterials();
  const { species } = useSpecies();
  const { currentGroup } = useAuth();
  const { open } = useDialog();

  // createProductの排他制御用
  const isCreatingProductRef = useRef(false);
  const createProduct = async (input: GraphQLInput) => {
    if (isCreatingProductRef.current) return;
    isCreatingProductRef.current = true;
    try {
      const orderMax = _.maxBy<Product>(data, "order")?.order ?? -1;
      const { id: productId } = (await create("createProductBom", {
        ...input,
        projectId: project?.id,
        order: orderMax + 1,
      })) as unknown as { id: string };
      // 作成したProductのElementに空更新をかけてCost関連を設定させる
      if (currentGroup) {
        // 更新漏れを防ぐため2回更新を流す
        await updateArbitraryProductBomElement(
          productId,
          { id: productId },
          allMaterials,
          species,
          currentGroup,
          true
        );
        await updateArbitraryProductBomElement(
          productId,
          { id: productId },
          allMaterials,
          species,
          currentGroup,
          true
        );
      }
      // 関連するProjectのupdatedを更新
      if (project !== undefined) {
        updateProject({ id: project.id });
      }
    } catch (error) {
      refetch(variables, { progress: false });
    } finally {
      isCreatingProductRef.current = false;
    }
  };

  // createNonPackProductの排他制御用
  const isCreatingNonPackroductRef = useRef(false);
  const createNonPackProduct = async (input: GraphQLInput) => {
    if (isCreatingNonPackroductRef.current) return;
    isCreatingNonPackroductRef.current = true;
    try {
      const orderMax = _.maxBy<Product>(data, "order")?.order ?? -1;
      await create("createProduct", {
        ...input,
        packageTypeId: "NonPack",
        packageTypeName: "NonPack",
        projectId: project?.id,
        order: orderMax + 1,
      });
      // 関連するProjectのupdatedを更新
      if (project !== undefined) {
        updateProject({ id: project.id });
      }
    } catch (error) {
      refetch(variables, { progress: false });
    } finally {
      isCreatingNonPackroductRef.current = false;
    }
  };

  const copyProduct = async (input: GraphQLInput) => {
    setCopying(true);
    const orderMax = _.maxBy<Product>(data, "order")?.order ?? -1;
    await create("copyProduct", {
      ...input,
      order: orderMax + 1,
    });
    setCopying(false);
    // 関連するProjectのupdatedを更新
    if (project !== undefined) {
      updateProject({ id: project.id });
    }
  };

  const updateProduct = async (input: GraphQLInput) => {
    if (!data) return;

    // 指示書Noが更新された時はケースの名前も一緒に更新
    if (input.productNumber) {
      const cases = getUpdatedCaseInputByProductNumber(data, input);
      input = {
        ...input,
        cases: cases,
      };
    }

    await update("updateProduct", {
      ...input,
    });
  };

  /**
   * 梱包を仮に戻す、仮に梱包の写真が一枚でも共有されていたらDialogで確認をする
   * @param productId 仮に戻すProductのID
   * @param refetchFuncs 仮に戻した後に再取得する関数配列
   */
  const updateToPending = async (
    productId: string,
    refetchFuncs: ((options: LoadOptions) => Promise<void>)[]
  ) => {
    if (!project) return;
    // 解除対象のProductのImageが共有されていたらDialogで解除の確認をする
    if (isShared && isSharedProduct(project, productId)) {
      open({
        title: "仮に戻しますか？",
        content: "仮に戻すと写真の共有は解除されます",
        okText: "はい",
        onOk: async () => {
          const excludeKeys =
            project.externalSharedImageKeys
              ?.filter((key) => key && isProductCaseKey(key, productId))
              .map((key) => key ?? "") ?? [];
          await updateExternalSharedImageKeys([], excludeKeys);
          await updateProduct({
            id: productId,
            readyToPack: false,
          });
          await Promise.all(
            refetchFuncs.map((func) => func({ progress: false }))
          );
        },
      });
    } else {
      await updateProduct({
        id: productId,
        readyToPack: false,
      });
      await Promise.all(refetchFuncs.map((func) => func({ progress: false })));
    }
  };

  // TODO: delete photos of product
  const removeProduct = async (id: string) => {
    open({
      title: "貨物を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => await remove("deleteProductBom", { productId: id }),
    });
  };

  const linkProductToQuoteLine = async (
    productId: string,
    quoteLineId: string
  ) => {
    await update("linkProductToQuoteLine", {
      productId,
      quoteLineId,
    });
  };

  const unlinkProductFromQuoteLine = async (
    productId: string,
    quoteLineId: string
  ) => {
    await update("unlinkProductFromQuoteLine", {
      productId,
      quoteLineId,
    });
  };

  return (
    <ProductsContext.Provider
      value={{
        products: data,
        loading,
        creating,
        copying,
        create: createProduct,
        createNonPack: createNonPackProduct,
        copy: copyProduct,
        update: updateProduct,
        updateToPending,
        remove: removeProduct,
        link: linkProductToQuoteLine,
        unlink: unlinkProductFromQuoteLine,
        refetch: async (options) => await refetch(variables, options),
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => {
  const productsContext = useContext(ProductsContext);

  if (productsContext === undefined) {
    throw new Error("useProducts must be within ProductsProvider");
  }

  return productsContext;
};
